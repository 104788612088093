<template>
  <div>

    <a-table
      :dataSource="tableObject.data"
      :columns="columns"
      :loading="tableObject.loading"
      :row-key="record => record.jsonApi.id"
      :pagination="tableObject.pagination"
      @change="refreshResults"
      bordered
      :scroll="{ x: 1300 }">
      <div slot="filterDropdown"
           slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
           class='custom-filter-dropdown'>
        <a-input
          v-ant-ref="c => searchInput = c"
          :placeholder="`Buscar por ${column.title}`"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm)"
          style="width: 188px; margin-bottom: 8px; display: block;"
        />
        <a-button
          type='primary'
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          size="small"
          style="width: 90px; margin-right: 8px"
        >Buscar</a-button>
        <a-button
          @click="() => handleReset(clearFilters)"
          size="small"
          style="width: 90px"
        >Limpiar</a-button>
      </div>
      <a-icon slot="filterIcon" type='search' slot-scope="filtered" :style="{ color: filtered ? '#108ee9' : undefined }"/>
      <template slot="customRender" slot-scope="text">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
                <mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{fragment}}</mark>
                <template v-else>{{fragment}}</template>
            </template>
          </span>
        <template v-else>{{text}}</template>
      </template>
      <template slot="service" slot-scope="record">
        <small>
          <strong>
            Planta:
          </strong>
        </small>
        <small>
          {{ (record.manifests.servicePlannings.workCenters != null) ? record.manifests.servicePlannings.workCenters.code : '' }} - {{ (record.manifests.servicePlannings.workCenters != null) ? record.manifests.servicePlannings.workCenters.name : '' }}
        </small>
        <hr class="bg-primary">
        <small>
          <strong>
            Contrato:
          </strong>
        </small>
        <small>
          {{ record.sapMaterialContracts.clientContracts.contract_code }}
        </small>
        <hr class="bg-primary">
        <small>
          <strong>
            Servicio:
          </strong>
        </small>
        <small>
          {{ record.manifests.servicePlannings.sap_programming_code }}
        </small>
        <hr class="bg-primary">
        <small>
          <strong>
            Manifiesto:
          </strong>
        </small>
        <small>
          {{ record.manifests.number }}
        </small>
        <hr class="bg-primary">
        <small>
          <strong>
            Cliente:
          </strong>
        </small>
        <small>
          {{ record.sapMaterialContracts.clientContracts.meta.clientInfo }}
        </small>
      </template>
      <template slot="characteristics" slot-scope="record">
        <small><strong>Corriente:</strong></small>
        <small>
          {{record.sapMaterialContracts.materials.currentRelation.name}}
        </small>
        <hr class="bg-primary">
        <small><strong>Peligrosidad:</strong></small>
        <small>
        {{record.dangerousTypes.name}}
        </small>
      </template>
      <template slot="sap_name" slot-scope="record">
        <small>
          <strong>
            Residuo de SAP
          </strong>
        </small>
        <small>
          {{record.sapMaterialContracts.sapMaterials.code}} - {{record.sapMaterialContracts.sapMaterials.name}}
        </small>
        <hr class="bg-primary">
        <small>
          <strong>
            Residuo de Operación
          </strong>
        </small>
        <small>
          {{record.sapMaterialContracts.materials.name}}
        </small>
      </template>
      <template slot="disposal" slot-scope="record">
        {{record.name}}
      </template>
      <template slot="container_quantity" slot-scope="record">
        {{ (record.manifestData != null) ? ((record.manifestData.container_quantity != null) ? record.manifestData.container_quantity : '-') : '-' }}
      </template>
      <template slot="container_type_id" slot-scope="record">
        {{ (record.manifestData != null) ? ((record.manifestData.container_types != null) ? record.manifestData.container_types.name : '-') : '-' }}
      </template>
      <template slot="quantity" slot-scope="record">
        {{ (record.manifestData != null) ? record.manifestData.quantity : '-' }}
      </template>
      <template slot="u_m_s_id" slot-scope="record">
        {{ (record.manifestData != null) ? record.manifestData.ums.sap_code : ''}} - {{ (record.manifestData != null) ? record.manifestData.ums.name : '' }}
      </template>
      <template slot="container_quantity_real" slot-scope="record">
        {{ (record.realData != null) ? ((record.realData.container_quantity != null) ? record.realData.container_quantity : '-') : '-' }}
      </template>
      <template slot="container_type_id_real" slot-scope="record">
        {{ (record.realData != null) ? ((record.realData.container_types != null) ? record.realData.container_types.name : '-') : '-' }}
      </template>
      <template slot="diff_quantity" slot-scope="record">
        {{ (record.manifestData != null && record.manifestData != null) ? ((record.manifestData.container_quantity != null && record.realData.container_quantity != null) ? (record.manifestData.container_quantity - record.realData.container_quantity) : '-') : '-' }}
      </template>
      <template slot="incidents" slot-scope="record">
        {{ (record.dischargeMaterialIncidents != null) ? record.dischargeMaterialIncidents.code : ''}} - {{ (record.dischargeMaterialIncidents != null) ? record.name : '' }}
      </template>
      <template slot="visualInspections" slot-scope="record">
        <div v-if="record.visualInspections">
          <small v-if="record.visualInspections.shifts"><strong>Turno:</strong></small>
          <small v-if="record.visualInspections.shifts">{{ record.visualInspections.shifts.name }}</small>
          <hr class="bg-primary">
          <small v-if="record.visualInspections.shifts"><strong>Recibido:</strong></small>
          <small v-if="record.visualInspections.shifts">{{ moment(record.visualInspections.received_at).utc().format('DD/MM/YY HH:mm') }}</small>
          <hr class="bg-primary">
          <small v-if="record.visualInspections.shifts"><strong>Inspeccionado:</strong></small>
          <small v-if="record.visualInspections.shifts">{{ moment(record.visualInspections.inspected_at).utc().format('DD/MM/YY HH:mm') }}</small>
          <hr class="bg-primary">
          <small v-if="record.visualInspections.users"><strong>Validador:</strong></small>
          <small v-if="record.visualInspections.users">{{ record.visualInspections.users.name }}</small>
          <hr class="bg-primary">
          <small v-if="record.visualInspections">
            <a-icon v-if="record.visualInspections.status" type="check-circle" theme="twoTone" two-tone-color="#52c41a" aria-label="Autorizado" />
            <a-icon v-else type="clock-circle" theme="twoTone" two-tone-color="#ed2022" aria-label="Pendiente"/>
          </small>
        </div>
      </template>
      <!-- Columnas Personalizadas -->
      <template slot="actions" slot-scope="record">
        <responsive-button responsive="sm"
                           icon="arrow-right-circle-fill"
                           variant="primary" pill size="sm"
                           title=""
                           text="Ir"
                           @ClickEvent="clickButton(record.manifests.servicePlannings.jsonApi.id,'discharge_service_plannings')"
        />
        <hr class="bg-primary">
        <template v-if="typeForm !== 'controlMaterialInspectionVisual'">
          <div v-show="record.manifests.is_discharge_close">
            <strong class="text-primary">
              <b-icon icon="check-circle-fill"></b-icon>
              Descarga Cerrada
            </strong>
            <hr class="bg-primary">
            <div v-show="record.manifests.is_lab_close">
              <strong class="text-warning">
                <b-icon icon="thermometer"></b-icon>
                Laboratorio Afectado
              </strong>
            </div>
            <div v-show="!record.manifests.is_lab_close">
              <strong class="text-danger">
                <b-icon icon="x-circle-fill"></b-icon>
                <b-icon icon="thermometer"></b-icon>
                Laboratorio No Afectado
              </strong>
            </div>
            <hr class="bg-primary">
            <a-row>
              <div v-show="record.sampling_check_record">
              <span v-show="record.sampling_check === 'Muestreo'" class="text-secondary">
                <b-icon icon="calculator-fill"></b-icon> {{ record.sampling_check }}
              </span>
                <span v-show="record.sampling_check === 'Inspección Visual'" class="text-primary">
                <b-icon icon="binoculars-fill"></b-icon> {{ record.sampling_check }}
              </span>
              </div>
              <div v-show="!record.sampling_check_record">
                Sin Prueba
              </div>
            </a-row>
            <div v-show="authorizeCheck == 'Rechazado'">
              <hr class="bg-primary">
              <small class="text-danger">Sin acciones disponibles</small>
            </div>
            <div v-show="authorizeCheck != 'Rechazado'">
              <responsive-button responsive="sm"
                                 icon="thermometer"
                                 variant="warning" pill size="sm"
                                 title="Revisión"
                                 text=""
                                 @ClickEvent="showModal('labValidate', record)"
                                 v-show="!record.manifests.is_lab_close"
              />
            </div>
          </div>
          <div v-show="!record.manifests.is_discharge_close">
            <strong class="text-danger">
              <b-icon icon="x-circle-fill"></b-icon>
              Descarga No Cerrada
            </strong>
            <hr class="bg-primary">
            <div v-show="record.manifests.is_lab_close">
              <strong class="text-warning">
                <b-icon icon="thermometer"></b-icon>
                Laboratorio Afectado
              </strong>
            </div>
            <div v-show="!record.manifests.is_lab_close">
              <strong class="text-danger">
                <b-icon icon="x-circle-fill"></b-icon>
                <b-icon icon="thermometer"></b-icon>
                Laboratorio No Afectado
              </strong>
            </div>
            <hr class="bg-primary">
            <a-row>
              <div v-show="record.sampling_check_record">
              <span v-show="record.sampling_check === 'Muestreo'" class="text-secondary">
                <b-icon icon="calculator-fill"></b-icon> {{ record.sampling_check }}
              </span>
                <span v-show="record.sampling_check === 'Inspección Visual'" class="text-primary">
                <b-icon icon="binoculars-fill"></b-icon> {{ record.sampling_check }}
              </span>
              </div>
              <div v-show="!record.sampling_check_record">
                Sin Prueba
              </div>
            </a-row>
            <div v-show="authorizeCheck == 'Rechazado'">
              <hr class="bg-primary">
              <small class="text-danger">Sin acciones disponibles</small>
            </div>
            <div v-show="authorizeCheck != 'Rechazado'">
              <responsive-button responsive="sm"
                                 icon="pencil-square"
                                 variant="secondary" pill size="sm"
                                 title="Revisión"
                                 text=""
                                 @ClickEvent="showModal('operationValidate', record)"
              />
              <responsive-button responsive="sm"
                                 icon="thermometer"
                                 variant="warning" pill size="sm"
                                 title="Revisión"
                                 text=""
                                 @ClickEvent="showModal('labValidate', record)"
              />
            </div>
          </div>
          <a-row v-show="typeForm === 'inspectionMaterial'">
            <responsive-button responsive="sm"
                               icon="pencil-square"
                               variant="secondary" pill size="sm"
                               title="Editar"
                               text=""
                               @ClickEvent="showModal('edit', record)"
            />
            <responsive-button responsive="sm"
                               icon="trash-fill"
                               variant="danger" pill size="sm"
                               class="ml-2"
                               title="Eliminar"
                               text=""
                               @ClickEvent="deleteRecord(record)"
            />
          </a-row>
          <a-row v-show="typeForm === 'inspectionDischarge'">
            <a-row>
              <div v-show="record.authorize_check_record">
                <span v-show="record.authorize_check === 'Autorizado'" class="text-success">
                  <b-icon icon="check-circle-fill"></b-icon> {{ record.authorize_check }}
                </span>
                <span v-show="record.authorize_check === 'Rechazado'" class="text-danger">
                  <b-icon icon="x-circle-fill"></b-icon> {{ record.authorize_check }}
                </span>
                <span v-show="record.authorize_check === 'Cuarentena'" class="text-warning">
                  <b-icon icon="exclamation-triangle-fill"></b-icon> {{ record.authorize_check }}
                </span>
              </div>
              <div v-show="!record.authorize_check_record">
                Sin Autorización
              </div>
            </a-row>
            <a-row>
              <responsive-button responsive="sm"
                                 icon="pencil-square"
                                 variant="secondary" pill size="sm"
                                 title="Revisión"
                                 text=""
                                 @ClickEvent="showModal('operationValidate', record)"
              />
            </a-row>
          </a-row>
        </template>
        <template v-else>
          <span class="small font-weight-bold">Estatus de Inspección:</span>
          <span class="small font-weight-bold text-primary" v-if="record.visualInspections.status === 1 || record.visualInspections.status === '1' || record.visualInspections.status === true || record.visualInspections.status === 'true'">
            Autorizado
          </span>
          <span v-else class="small font-weight-bold text-warning">
            Pendiente
          </span>
          <hr class="bg-primary">
          <b-button-group size="sm">
            <b-button class="mr-1"  variant="warning" @click="OpenModal(record.visualInspections)" pill size="sm">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Editar">
                <b-icon icon="pencil-square"/> Editar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Editar">
                <b-icon icon="pencil-square"/>
              </div>
            </b-button>
          </b-button-group>
        </template>
      </template>
    </a-table>

    <!-- MODAL -->
    <b-modal title="Inspección Visual" ref="modal-visualIns" size="lg" @hide="CloseModal" >
      <b-container fluid>
        <!--FORMULARIO-->
        <a-form-model :model="resourceObj.form" :rules="rules" ref="ruleForm" enctype="multipart/form-data"
                      layout="vertical" :wrapper-col="{ span: 24}" v-show="!spinner">
          <!--SELECT -Responsable de la validación -->
          <a-form-model-item label="Responsable de la validación" ref="users" prop="users" >
            <a-select v-model="resourceObj.form.users"
                      placeholder="Responsable de la validación"
                      show-search
                      :disabled="disabledObject"
                      :filter-option="filterOption"
            >
              <a-select-option  v-for="option in usersCombo" :key="option.id" :value="option.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--SELECT -Responsable de la validación -->
          <!--SELECT -Turno -->
          <a-form-model-item label="Turno" ref="shifts" prop="shifts" >
            <a-select v-model="resourceObj.form.shifts"
                      placeholder="Turno"
                      show-search
                      :disabled="disabledObject"
                      :filter-option="filterOption">
              <a-select-option  v-for="option in shiftsCombo" :key="option.id" :value="option.id" >
                {{option.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--SELECT -Turno -->
          <!--SELECT -Estatus -->
          <a-form-model-item label="Estatus" ref="status" prop="status" >
            <a-select v-model="resourceObj.form.status"
                      placeholder="Estatus"
                      show-search
                      :disabled="disabledObject"
                      :filter-option="filterOption">
              <a-select-option  v-for="option in statusCombo" :key="option.value" :value="option.value" >
                {{option.label}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <!--SELECT -Estatus -->
          <a-row>
            <!--DATEPICKER - Fecha de Recepción-->
            <a-col :md="{ span: 12 }">
              <a-form-model-item  label="Fecha de Recepción" ref="inspected_at" prop="inspected_at" >
                <a-date-picker  v-model="resourceObj.form.received_at"
                                placeholder="Fecha de Recepción"
                                disabled
                                format="DD/MM/YYYY HH:mm"
                                size="large"
                                valueFormat="YYYY/MM/DD HH:mm"
                                style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <!--DATEPICKER - Fecha de Recepción-->
            <!--DATEPICKER - Fecha de inspección-->
            <a-col :md="{ span: 12 }">
              <a-form-model-item  label="Fecha de inspección" ref="inspected_at" prop="inspected_at" >
                <a-date-picker  v-model="resourceObj.form.inspected_at"
                                placeholder="Fecha de inspección"
                                showTime
                                format="DD/MM/YYYY HH:mm" size="large"
                                valueFormat="YYYY/MM/DD HH:mm"
                                :disabled="disabledObject"
                                style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <!--DATEPICKER - Fecha de inspección -->
          </a-row>
          <!--TEXT-AREA - Observaciones-->
          <a-form-model-item label="Observaciones" ref="text"  prop="text" >
            <a-textarea v-model.trim="resourceObj.form.text"
                        :auto-size="{ minRows: 4 }"
                        :disabled="disabledObject"
            />
          </a-form-model-item>
          <!--TEXT-AREA -Observaciones -->
          <!--Dropzone - arrayPhotos-->
          <a-form-model-item v-if="resourceObj.form.id"  label="Fotos" ref="arrayPhotos" prop="arrayPhotos">
            <div class="clearfix">
              <a-upload accept="image/*,.pdf" name="file"
                        :data="{'id': resourceObj.form.id, strategy:'VisualInspections'}"
                        :action="attachFileUrl"
                        list-type="picture-card"
                        :file-list="resourceObj.form.arrayPhotos"
                        :before-upload="BeforeUpload"
                        @preview="handlePreview"
                        @change="UploadPhotos"
                        :remove="RemovePhoto"
              >
                <div>
                  <b-icon icon="plus"/>
                  <div class="ant-upload-text">Cargar Evidencia</div>
                </div>
              </a-upload>
              <!-- MODAL para vista previa de imagen -->
              <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false" :zIndex="3000">
                <img alt="previewFile" style="width: 100%;" :src="previewImage" />
              </a-modal>
              <!-- MODAL para vista previa de imagen -->
            </div>
          </a-form-model-item>
          <fadeAlert text="La imagen cargada es ,mayor a 2MB" variant="danger" v-model="fadeAlter"/>
          <!--Dropzone - arrayPhotos-->
          <hr class="bg-primary">
          <div class="row" v-show="!disabledObject">
            <div class="col-md-4 offset-md-4 col-12">
              <b-button variant="primary" @click="Update" pill class="btn-block">
                <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/> Guardar
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                  <b-icon icon="archive"/>
                </div>
              </b-button>
            </div>
          </div>
          <div class="row mt'2">
            <div class="col-md-4 offset-md-4 col-12">
              <b-button variant="warning" pill class="btn-block" :to="{ name: 'visualInspectionPrint', params: {id: resourceObj.form.id}}">
                <div class="d-none d-md-block" v-b-tooltip.hover title="Imprimir">
                  <b-icon icon="printer"/> Imprimir
                </div>
                <div class="d-md-none" v-b-tooltip.hover title="Imprimir">
                  <b-icon icon="printer"/>
                </div>
              </b-button>
            </div>
          </div>
        </a-form-model>
        <my-spinner :load="spinner"/>
        <!--FORMULARIO-->
      </b-container>
      <template v-slot:modal-footer>
        <simple-modal-footer @ClickEvent="CloseModal"/>
      </template>
    </b-modal>
    <!-- MODAL -->

  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { fractalMixin } from '@/mixins/fractalMixin'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'materialsTable',
  mixins: [fractalMixin],
  props: {
    tableObject: Object,
    typeForm: {
      default: 'inspectionDischarge',
      type: String,
    },
    authorizeCheck: {
      default: 'Sin Autorización',
      type: String,
    },
  },
  data () {
    return {
      // Varialbles propias para el funcionamiento de la tabla
      searchText: '',
      searchInput: null,
      // Varialbles propias para el funcionamiento de la tabla
      // Columnas de la tabla
      columns: [
        {
          title: 'Servicio',
          dataIndex: '',
          key: 'service',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'service',
          },
        },
        {
          title: 'Residuo SAP',
          dataIndex: '',
          key: 'sap_name',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'sap_name',
          },
        },
        {
          title: 'Características',
          dataIndex: '',
          key: 'dangerousTypes',
          // key: 'characteristics',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'characteristics',
            // customRender: 'dangerous',
          },
        },
        {
          title: 'Manifestado',
          class: 'small text-center',
          children: [
            {
              title: 'No. Contenedores',
              dataIndex: '',
              key: 'container_quantity',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'container_quantity',
              },
            },
            {
              title: 'Tipo Contenedores',
              dataIndex: '',
              key: 'container_type_id',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'container_type_id',
              },
            },
            {
              title: 'Cantidad Total',
              dataIndex: '',
              key: 'quantity',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'quantity',
              },
            },
            {
              title: 'UM',
              dataIndex: '',
              key: 'u_m_s_id',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'u_m_s_id',
              },
            },
          ],
        },
        {
          title: 'Real',
          class: 'small text-center',
          children: [
            {
              title: 'No. Contenedores',
              dataIndex: '',
              key: 'container_quantity_real',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'container_quantity_real',
              },
            },
            {
              title: 'Tipo Contenedores',
              dataIndex: '',
              key: 'container_type_id_real',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'container_type_id_real',
              },
            },
          ],
        },
        {
          title: 'Diferencias',
          class: 'small text-center',
          children: [
            {
              title: 'No. Contenedores',
              dataIndex: '',
              key: 'diff_quantity',
              class: 'small text-center',
              scopedSlots: {
                customRender: 'diff_quantity',
              },
            },
          ],
        },
        {
          title: 'Incidentes',
          dataIndex: '',
          key: 'dischargeMaterialIncidents',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'incidents',
          },
        },
        {
          title: 'Tipo de Manejo',
          dataIndex: 'disposalTypes',
          key: 'disposalTypes',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'disposal',
          },
        },
        {
          title: 'Detale de Inspección',
          dataIndex: '',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'visualInspections',
          },
        },
        {
          title: 'Acciones',
          dataIndex: '',
          class: 'small text-center',
          scopedSlots: { customRender: 'actions' },
        },
      ],
      // ************************************************************************************************************
      // Formulario del recurso
      disabledObject: false,
      resourceObj: {
        resourceType: 'visualInspections',
        form: {
          id: '',
          dischargeMaterials: undefined,
          users: undefined, // Responsable de validacion
          shifts: undefined, // turno
          status: 0, // Estatus
          received_at: '',
          inspected_at: '',
          text: '',
          arrayPhotos: [],
        },
        relationships: ['dischargeMaterials', 'users', 'shifts'],
      },
      rules: {
        users: [{
          required: true,
          message: 'El campo Responsable de la Validación es obligatorio.',
          trigger: 'blur',
        }],
        shifts: [{
          required: true,
          message: 'El campo Turno es obligatorio.',
          trigger: 'blur',
        }],
        status: [{
          required: true,
          message: 'El campo Estatus es obligatorio.',
          trigger: 'blur',
        }],
        received_at: [{
          required: true,
          message: 'El campo Fecha de Recepción es obligatorio.',
          trigger: 'change',
        }],
        inspected_at: [{
          required: true,
          message: 'El campo Fecha de Inspección es obligatorio.',
          trigger: 'change',
        }],
        text: [{
          required: true,
          message: 'El campo Observaciones es obligatorio.',
          trigger: 'blur',
        }],
        // arrayPhotos: [{
        //   required: true,
        //   message: 'La evidencia Fotográfica es obligatoria.',
        //   trigger: 'blur',
        // }],
      },
      // Combos de formulario
      usersCombo: [],
      shiftsCombo: [],
      statusCombo: [{ value: 0, label: 'Pendiente' }, { value: 1, label: 'Autorizado' }],
      // Filtro s de busqueda
      filterValue: '',
      filterWorkCenter: '',
      filterServicePlanning: '',
      // Combos de filtros
      servicesCombo: [],
      // Controles auxiliares y para el flujo del DOM
      attachFileUrl: `${process.env.VUE_APP_API_URL}/attachFile`,
      fadeAlter: false,
      spinner: false,
      previewVisible: false,
      previewImage: '',
    }
  },
  methods: {
    ...mapActions('servicePlannings', ['SET_SERVICEPLANNINGID', 'GOTO_SERVICEPLANNINGID']),
    handleSearch (selectedKeys, confirm) {
      confirm()
      this.searchText = selectedKeys[0]
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    refreshResults(paginationEvent = {}) {
      this.$emit('Filter', paginationEvent)
    },
    showModal(action, data = {}) {
      this.$emit('Modal', action, data)
    },
    deleteRecord(data = {}) {
      this.$emit('DeleteRecord', data)
    },
    checkUpdate(checkValue, checkType, data = {}) {
      this.$emit('CheckUpdate', checkValue, checkType, data)
    },
    clickButton(serviceId, origin) {
      this.GOTO_SERVICEPLANNINGID({
        id: serviceId,
        name: origin,
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    validateDisableObject(statusValue) {
      this.disabledObject = statusValue === 1
    },
    async FetchCombos() {
      this.usersCombo = await this.GetResource('/users')
      this.shiftsCombo = await this.GetResource('/shifts')
      // const params = {
      //   'filter[visualInspections]': '',
      // }
      // this.servicesCombo = await this.GetResource('servicePlannings', params)
    },
    async OpenModal(data = {}) {
      this.$refs['modal-visualIns'].show()
      this.spinner = true
      this.resourceObj.form.id = data.jsonApi.id
      this.resourceObj.form.dischargeMaterials = data.discharge_material_id.toString()
      this.resourceObj.form.users = data.user_id.toString()
      this.resourceObj.form.shifts = (data.shift_id) ? data.shift_id.toString() : undefined
      this.resourceObj.form.status = data.status ? 1 : 0
      // Call function to disable object or not
      this.validateDisableObject(this.resourceObj.form.status)
      this.resourceObj.form.received_at = data.received_at
      this.resourceObj.form.inspected_at = data.inspected_at
      this.resourceObj.form.text = data.text
      if (data.photos === null) {
        this.resourceObj.form.arrayPhotos = []
      } else this.resourceObj.form.arrayPhotos = data.photos
      await this.FetchCombos()
      this.spinner = false
    },
    CloseModal() {
      this.$refs['modal-visualIns'].hide()
      this.resourceObj.form = {
        id: '',
        dischargeMaterials: undefined,
        users: undefined, // Responsable de validacion
        shifts: undefined, // turno
        status: 0, // Estatus
        received_at: '',
        inspected_at: '',
        text: '',
        arrayPhotos: [],
      }
    },
    async UploadPhotos(info) {
      const { file } = info
      if (Object.hasOwnProperty.call(file, 'response') && Object.hasOwnProperty.call(file.response, 'photos')) {
        this.resourceObj.form.arrayPhotos = file.response.photos
        await this.FetchTable()
      }
    },
    BeforeUpload(file) {
      // Confirmacion de tamaño del archivo
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.fadeAlter = true
        return false
      } else {
        this.resourceObj.form.arrayPhotos.push({
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: undefined,
        })
        this.resourceObj.form.arrayPhotos.slice(-2)
        return true
      }
    },
    async RemovePhoto(file) {
      const params = {
        id: this.resourceObj.form.id,
        url: file.url,
        strategy: 'VisualInspections',
      }
      await this.DeleteFile('/dettachFile', params, async(resp) => {
        this.resourceObj.form.arrayPhotos = resp.photos
        await this.FetchTable()
      })
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    async Update() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          await this.PutResource(this.resourceObj, async() => {
            this.spinner = false
            this.CloseModal()
            this.refreshResults()
          })
        } else return false
      })
    },
  },
}
</script>

<style scoped>

</style>
